import React from "react"

const CGU = () => {
  return (
    <div className="pvm_cgu">
      <div className="py-2">
        <h1>
          <strong>Conditions générales d’utilisation Patient</strong>
        </h1>
        <p>
          <strong>
            Conditions générales d’utilisation de la Solution opérée par
            CURECALL d’accompagnement des Patients en parcours ambulatoire pour
            une chirurgie réfractive.
          </strong>
        </p>
        <p>
          Toute utilisation des services CURECALL est soumise au préalable à la
          prise de connaissance et à l’acceptation expresse et sans réserve des
          présentes Conditions Générales d’Utilisation par le Patient.
        </p>
        <p>
          L’acceptation expresse du Patient se manifeste par l’acceptation des
          présentes conditions générales d’utilisation transmises par le SMS «
          Parcours de consentement » que reçoit le Patient.
        </p>
        <p>
          Le SMS « Parcours de consentement » à destination du Patient contient
          :
        </p>
        <ul>
          <li>
            Le nom du Client au sein duquel exerce le Professionnel de santé
            s’occupant du Patient ;
          </li>
          <li>Le nom du Professionnel de Santé s’occupant du Patient ;</li>
          <li>La finalité du traitement de des données personnels ;</li>
          <li>Un lien de redirection pour consulter les présentes CGU.</li>
        </ul>
        <p>
          Le Centre médical ophtalmologique et/ou le Professionnel de santé sont
          informés que le recueil du consentement des patients est une condition
          à l’utilisation de la Solution.
        </p>
        <p>Le Patient reconnait avoir été informé que :</p>
        <ul>
          <li>
            Le Centre médical ophtalmologique recourt à la Solution CURECALL et
            est Client de Curecall,
          </li>
          <li>
            Le Centre médical ophtalmologique met à disposition des
            Professionnels de santé exerçant dans le Centre Médical
            Ophtalmologique la solution CURECALL,
          </li>
          <li>
            Le Professionnel de santé utilise essentiellement la Solution pour
            le suivi du Patient.
          </li>
        </ul>
        <p>
          CURECALL a conçu et développé une plateforme numérique dont l’une de
          ses fonctionnalités est d’assurer le continuum entre le centre médical
          et/ou l’hôpital, les professionnels de santé, le domicile et les
          patients au moment de l’instauration des traitements. Il s’agit d’un
          agent conversationnel automatisé par SMS permettant de rassurer et
          d’accompagner le patient en parcours ambulatoire pour une chirurgie
          réfractive, étape par étape sur les démarches administratives et les
          éléments à apporter pour l’intervention ; des contenus pédagogiques
          sur l’intervention et son déroulé et l’automatisation du suivi post
          intervention afin de contrôler toute anomalie (ci-après dénommée la «
          Solution »).
        </p>
        <p>
          CURECALL est uniquement un prestataire de service intermédiaire entre
          le Patient et le Professionnel de Santé et/ou le Centre médical
          ophtalmologique.
        </p>
        <p>
          La Solution est dédiée au suivi des Patients de plus de 18 ans
          souhaitant de l’information fiable sur un traitement ou un procédé
          chirurgical (ci-après le « Patient » ou les « Patients »). Via
          l’instauration d’une communication par SMS, la Solution assure
          également une aide à l’accompagnement lors d’une prise en charge
          ambulatoire ou d’un suivi de pathologie chronique.
        </p>
        <p>
          La Solution vient au soutien de la prise en charge des Patients par
          leurs Professionnels de Santé dans le cadre de protocoles de suivi
          laissée(s) au choix du Professionnel de Santé et/ou du Centre médical
          ophtalmologique (ci-après «le Centre médical ophtalmologique ») au
          sein duquel le Patient est pris en charge.
        </p>
        <p>
          La Solution permet de rassurer et d’accompagner le patient en parcours
          ambulatoire pour une chirurgie réfractive étape par étape sur :
        </p>
        <ul>
          <li>
            Les démarches administratives et les éléments à apporter pour
            l’intervention ;
          </li>
          <li>Des contenus pédagogiques sur l’intervention et son déroulé ;</li>
          <li>
            Automatisation du suivi post intervention afin de contrôler toute
            anomalie.
          </li>
        </ul>
        <p>
          Ci-après, le Patient trouvera les informations relatives au traitement
          de ses données personnelles dans le cadre de la mise en œuvre de la
          Solution impliquant la diffusion d’informations par SMS.
        </p>
        <p>
          <strong>DEFINITIONS</strong>
        </p>
        <ul>
          <li>
            <strong>« Professionnel de Santé » :</strong> les professionnels de
            santé présents au sein du Centre médical ophtalmologique et qui
            utilisent la Solution CURECALL pour le suivi de leurs patients et à
            qui le patient reconnait la qualité de membre de l’équipe de soins
            en charge de son suivi.
          </li>
          <li>
            <strong>« Centre Médical Ophtalmologique » :</strong> le Centre
            médical ophtalmologique ayant conclu un contrat de prestation de
            services avec CURECALL et qui est Client de CURECALL.
          </li>
          <li>
            <strong>« Solution</strong> » : agent conversationnel automatisé par
            SMS permettant de rassurer et d’accompagner le patient en parcours
            ambulatoire pour une chirurgie réfractive étape par étape sur :
          </li>
          <li>
            Les démarches administratives et les éléments à apporter pour
            l’intervention ;
          </li>
          <li>Des contenus pédagogiques sur l’intervention et son déroulé ;</li>
          <li>
            Automatisation du suivi post intervention afin de contrôler toute
            anomalie.
          </li>
          <li>
            <strong>« Patient(s) »</strong> désigne la personne qui bénéficier
            d’un traitement ou un soin dont une partie du suivi est opéré par la
            Solution CURECALL.
          </li>
          <li>
            <strong>« Données Patient » :</strong> Les données personnelles des
            Patients collectées par CURECALL dans le cadre de la mise en œuvre
            de la Solution.
          </li>
        </ul>
        <p>
          <strong>1. NOTIFICATIONS PAR SMS ADRESSÉES AU PATIENT</strong>
        </p>
        <p>
          Le Patient autorise CURECALL à envoyer des notifications par SMS via
          la Solution, concernant l’organisation d’une intervention ou d’un
          rendez-vous à venir. Plus précisément les SMS peuvent porter sur : (i)
          confirmation, annulation ou rappel de rendez- vous ; (ii) information
          sur les soins à savoir les consignes pré et post opératoire,
          informations administratives en lien avec la prise en charge du
          Patient ; (iii) rappels de renouvellement ; (iv) tout type
          d’informations relatif aux rendez-vous pris auprès du Centre Médical
          Ophtalmologique et/ou du Professionnels de Santé.
        </p>
        <p>Le Patient est informé qu’il pourra recevoir deux types de SMS :</p>
        <ul>
          <li>
            SMS unidirectionnels d&#39;information, c’est-à-dire des SMS
            n’appelle pas à la transmission d’informations par le Patient en
            réponse ;
          </li>
          <li>
            SMS bidirectionnels, c’est-à-dire des SMS qui visent à collecter des
            données auprès du Patient ; le contenu de ces SMS et les données
            demandés auprès du Patient font l’objet d&#39;une validation du
            Centre Médical Ophtalmologique via le Professionnel de Santé.
          </li>
        </ul>
        <p>
          Ces SMS sont envoyés par CURECALL pour le compte du Professionnel de
          Santé avec lequel le Patient a pris rendez-vous.
        </p>
        <p>
          CURECALL déclinent toute responsabilité en cas de non-réception d’une
          notification pour des raisons techniques indépendantes de leur
          volonté.
        </p>
        <p>
          Le Patient peut choisir de ne pas recevoir de SMS. Pour cela, il doit
          en faire la demande auprès de CURECALL à l’adresse suivante :
          donnees.personnelles@curecall.com. CURECALL est seul responsable de la
          désactivation de l’envoi de SMS si le Patient ne souhaite pas les
          recevoir.
        </p>
        <p>
          Les coordonnées du Patient sont saisies par et sous la responsabilité
          du Centre Médical Ophtalmologique. En conséquence CURECALL décline
          toute responsabilité en cas d’erreur dans la saisie des coordonnées
          rendant impossible l’envoi de tout SMS nécessaire au bon
          fonctionnement et à la bonne administration du service
          télésurveillance.
        </p>
        <p>
          <strong>
            2. OBLIGATIONS, RESPONSABILITES ET GARANTIES DE CURECALL
          </strong>
        </p>
        <p>
          CURECALL met en place les moyens et les mesures nécessaires à la bonne
          marche et au maintien de la continuité et de la qualité des services
          fournies. Les données concernant le Patient sont recueillies par le
          Centre Médical Ophtalmologique et/ou le Professionnel de santé
          transmises à CURECALL pour la mise en œuvre de la Solution. CURECALL
          est amenée à collecter des données pour le compte du Centre Médical
          Ophtalmologique qui recourt à la Solution pour permettre aux
          Professionnels de santé de rassurer et accompagner le Patient en
          parcours ambulatoire pour une chirurgie réfractive.
        </p>
        <p>
          CURECALL s’engage à respecter le caractère strictement confidentiel de
          toutes les données à caractère personnel et fichiers contenant des
          données à caractère personnel, dont les données de santé (ci-après
          “Données Patient”), qui lui sont communiqués par le Centre Médical
          Ophtalmologique via le Professionnel de Santé et le Patient. CURECALL
          reconnaît que les Données Patient sont soumises au respect de la loi «
          Informatique et libertés » du 6 janvier 1978 ainsi qu’au Règlement
          2016/679 du Parlement Européen et du Conseil du 27 avril 2016
          (applicable à compter du 25 mai 2018), et relèvent de la vie privée et
          du secret professionnel. CURECALL s’engage à mettre en place toutes
          les procédures nécessaires pour en assurer la confidentialité et la
          plus grande sécurité et s’engage à ne pas traiter ou consulter les
          Données Patient à d’autres fins que la stricte exécution des
          prestations qu’il réalise et telles que décrites dans les présentes
          conditions générales d’utilisation du service CURECALL
          d’accompagnement des Patients en parcours ambulatoire pour une
          chirurgie réfractive .
        </p>
        <p>
          Le Patient reconnaît que CURECALL n’intervient pas en tant que
          Professionnel de Santé ou et n’exerce pas d’activité de soins ou de
          santé, se limitant à cet égard, à un rôle de simple intermédiaire et
          prestataire technique.
        </p>
        <p>
          Le Patient reconnaît que CURECALL ne peut être tenue responsable d’une
          interruption de services ou d’un retard d’exécution ne relevant pas de
          son contrôle et, en particulier, que la fourniture des services dépend
          de la fiabilité, de la disponibilité et de la continuité de connexions
          d’un certain nombre de parties tierces (exploitants du réseau des
          télécommunications, Internet public, infrastructure présente dans les
          Centre Médical Ophtalmologique, etc.) ainsi que de l’exactitude et de
          l’intégrité des Données Patient et des informations saisies par le
          Professionnel de Santé. CURECALL pourra être amenée à suspendre les
          services à des fins de maintenance programmée par CURECALL ou l’un de
          ses sous-traitants ou en cas d’impératif technique (maintenance
          d’urgence).
        </p>
        <p>
          CURECALL n’est en aucun cas responsable en cas de litige entre les
          Patients et le Centre Médical Ophtalmologique, lié à l’utilisation de
          la Solution, de toute réclamation, demande d’indemnisation, et de
          manière générale de tout dommage présent ou futur, présumé ou non,
          constaté ou non, résultant de manière directe ou indirecte de ces
          réclamations.
        </p>
        <p>
          En outre, la responsabilité de CURECALL ne peut pas être recherchée
          pour des actes (i) résultant d’un acte ou d’une négligence du Patient
          ou (ii) qui ne seraient pas conformes à la réglementation applicable
          et/ou aux présentes conditions générales d’utilisation. CURECALL
          décline toute responsabilité en cas de litige, quelle qu’en soit la
          cause, entre un Patient et un Professionnel de Santé, non imputable à
          CURECALL.
        </p>
        <p>
          CURECALL ne peut en aucun cas être tenue responsable (i) de la
          non-exécution ou de la mauvaise exécution d’un acte ou d’un soin par
          un Professionnel de Santé, (ii) du contenu d’un document du
          Professionnel de Santé.
        </p>
        <p>
          <strong>3.</strong> <strong>OBLIGATIONS GENERALES DU PATIENT</strong>
        </p>
        <p>Le Patient s’engage à :</p>
        <ol>
          <li>
            respecter les termes des présentes Conditions générales
            d’utilisation ;
          </li>
          <li>
            communiquer au Centre médical ophtalmologique et/ou au Professionnel
            de Santé, dans les délais convenus, toutes les informations
            nécessaires à la mise en œuvre la Solution et tenir promptement
            informé le Centre médical ophtalmologique et/ou Professionnel de
            Santé de toute modification concernant ses Données Patient ;
          </li>
          <li>
            Les frais de communications électroniques (tels que les coûts
            téléphoniques et les coûts d’accès à Internet) résultant de
            l’utilisation de la Solution sont à la charge exclusive du Patient.
          </li>
          <li>
            Se prémunir contre les risques de perte ou de piratage de données,
            fichiers et programmes en utilisant des progiciels antivirus
            régulièrement mis à jour ;
          </li>
          <li>
            Restreindre l’accès et observer la plus grande confidentialité
            s’agissant des modes d’accès aux services de télésurveillance, quels
            qu’ils soient, et d’une manière générale, sécuriser ces modes
            d’accès afin d’empêcher une utilisation non autorisée des services
            de télésurveillance ;
          </li>
          <li>
            Utiliser les services de télésurveillance dans le respect des lois
            et règlements applicables ;
          </li>
          <li>
            Ne pas utiliser la Solution d’une façon qui serait susceptible de
            nuire à la réputation de CURECALL et/ou des Professionnels de Santé
            ou d’une façon inconvenante (en ce compris tout propos
            diffamatoires, indécents, inappropriés, menaçants, haineux,
            injurieux tenus à l’oral ou à l’écrit) ;
          </li>
          <li>
            Ce que les Données Patient qu’il communique dans le cadre de
            l’utilisation de la Solution ne portent pas préjudice aux droits des
            tiers et à être autorisé à les diffuser ;
          </li>
          <li>
            N’utiliser la Solution qu’à des fins privées.
            <ol>
              <li>
                Consulter un Professionnel de Santé en cas d’urgence ou de doute
                sur une information transmise via SMS.
              </li>
            </ol>
          </li>
        </ol>
        <p>
          Les données à caractère personnel du Patient sont collectées et
          traitées dans le cadre de la mise en œuvre de la Solution opéré par
          CURECALL et notamment au titre des échanges SMS via la Solution.
        </p>
        <p>
          <strong>4. L’UTILISATION DES DONNEES DU PATIENT</strong>
        </p>
        <p>
          Afin de permettre au Patient de prendre facilement connaissance des
          modalités de traitement de ses données par CURECALL en qualité de
          Responsable de traitement :
        </p>
      </div>

      <table>
        <thead>
          <tr>
            <th>
              <strong>CARACTÉRISTIQUES</strong> <strong>DE TRAITEMENT</strong>{" "}
              <strong>DES DONNÉES À</strong>{" "}
              <strong>CARACTÈRE PERSONNEL</strong>
            </th>
            <th>
              <strong>TRAITEMENT DES</strong>{" "}
              <strong>DONNÉES À CARACTÈRE PERSONNEL</strong>{" "}
              <strong>DU PATIENT</strong>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Base légale du traitement</strong>{" "}
              <strong>et finalité du traitement</strong>
            </td>
            <td>
              Le traitement des données du Patient afin de leur permettre de
              bénéficier de la Solution est mis en œuvre sur la base du
              consentement explicite du Patient et en exécution des CGU par le
              biais de la Solution. Articles 6.1.b) et 9.2.a) du RGPD <br /> La
              finalité du recueil et du traitement des données du Patient est
              d’assurer la prise en charge du patient dans le cadre de la
              Solution permettant le suivi du Patient. <br />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Données collectées</strong> <strong>et</strong>{" "}
              <strong>traitées</strong>
            </td>
            <td>
              Nom, Prénom Date de naissance Numéros de téléphone Coordonnées
              personnelles du Patient ; Numéro de sécurité sociale ; <br />{" "}
              <br />
            </td>
          </tr>
          <tr>
            <td>
              <strong>Destinataires des</strong> <strong>données</strong>
            </td>
            <td>
              Les données à caractère Personnel du Patient sont strictement
              destinées : <br /> Au Patient A CURECALL Aux Professionnels de
              Santé constituant l’ équipe de soins dans le cadre de la Solution.
              Au Centre Médical Ophtalmologique Aux personnels strictement
              habilités de l’hébergeur de données de santé, dans la limite de
              leurs attributions respectives Aux prestataires et personnels
              habilités de CURECALL à des fins administratives et de
              coordination et techniques, le cas échéant. Le DPO de Curecall
              garantit que les données à caractère personnel ne seront
              transmises à aucun tiers non autorisé, sans son accord.
            </td>
          </tr>
          <tr>
            <td>
              <strong>Durée de</strong>{" "}
              <strong>conservation des données</strong>
            </td>
            <td>
              Les données à caractère personnel sont conservées pendant toute la
              durée nécessaire à la mise en œuvre de la Solution. <br /> A la
              fin de l’utilisation de la Solution, CURECALL conserve pendant une
              durée de trois ans les données en archivage intermédiaire. A
              l’issue de ce délai de 3 ans les données seront supprimées. <br />
            </td>
          </tr>
        </tbody>
      </table>
      <div className="py-2">
        <ol>
          <li>
            <strong className="mt-2 block">LA SÉCURITÉ DES DONNÉES</strong>
          </li>
        </ol>
        <p>
          La sécurité des données porte sur les mesures prises par CURECALL afin
          de protéger les données des faits suivants :
        </p>
        <ul>
          <li>La destruction,</li>
          <li>La perte,</li>
          <li>L’altération,</li>
          <li>
            La divulgation non autorisée de données à caractère personnel
            transmises, conservées ou traitées,
          </li>
          <li>
            L’accès non autorisé à de telles données, de manière accidentelle ou
            illicite.
          </li>
          <li>
            Afin de garantir la sécurité des données à caractère personnel, des
            mesures techniques et organisationnelles
          </li>
          <li>
            Appropriées sont mises en œuvre compte tenu de l&#39;état des
            connaissances, des coûts, de la nature, de la portée, du contexte et
            des finalités des traitements afin de garantir un niveau de sécurité
            adapté aux risques.
          </li>
          <li>
            En particulier et chaque fois que nécessaire, les mesures suivantes
            ont été prises :
            <ul>
              <li>
                L’anonymisation et le chiffrement des données à caractère
                personnel ;
              </li>
              <li>
                Le déploiement de moyens permettant de garantir la
                confidentialité, l&#39;intégrité, la disponibilité et la
                résilience constante des systèmes et des traitements ;
              </li>
              <li>
                Le déploiement de moyens permettant de rétablir la disponibilité
                des données à caractère personnel
              </li>
              <li>
                Et l&#39;accès à celles-ci dans des délais appropriés en cas
                d&#39;incident physique ou technique ;
              </li>
            </ul>
          </li>
          <li>
            La mise en œuvre d’une procédure visant à tester, à analyser et à
            évaluer régulièrement l&#39;efficacité des mesures techniques et
            organisationnelles pour assurer la sécurité des traitements.
          </li>
        </ul>
        <p>
          Ainsi, le Centre Médical Ophtalmologique, CURECALL et le sous-traitant
          de services d’hébergement auquel fait appel CURECALL se sont dotés de
          dispositifs adaptés et conformes aux règles de l’art et aux normes
          imposées, pour garantir la protection des données personnelles.
        </p>
        <p>
          Le sous-traitant de service d’hébergement est{" "}
          <strong>Pictime Groupe</strong> qui exerce son activité en tant qu’
          Hébergeur de Données de Santé (dits “HDS”) bénéficiant de la
          certification de l’ANS (l’Agence du Numérique en Santé).
        </p>
        <p>
          En particulier, dans le cadre du suivi médical digitalisé opéré par
          CURECALL, les données de santé à caractère personnel sont hébergées
          chez un hébergeur agréé ou certifié au sens de l’article L. 1111-8 du
          Code de la santé publique.
        </p>
        <p>
          Il est rappelé que dans le cadre du suivi médical digitalisé opéré par
          CURECALL cet hébergement est nécessaire pour :
        </p>
        <ul>
          <li>
            Garantir la conservation, l’archivage et la sécurité des données de
            santé à caractère personnel,
          </li>
          <li>
            Assurer le respect des exigences de confidentialité, de sécurité et
            de pérennité des données.
          </li>
          <li>
            A ce titre, le Patient et le Centre Médical Ophtalmologique sont
            informés qu’ils bénéficient du droit de s’opposer à l’hébergement
            des données par cet hébergeur agréé ou certifié pour un motif
            légitime en s’adressant aux coordonnées suivantes :{" "}
            <a href="mailto:donnees.personnelles@curecall.com">
              donnees.personnelles@curecall.com
            </a>{" "}
            .
          </li>
        </ul>
        <p>
          L’opposition à l’hébergement des données par cet hébergeur agréé ou
          certifié entrainera l’impossibilité de fournir la Solution.
        </p>
        <p>
          <strong>6. DUREE DE CONSERVATION DES DONNEES PERSONNELLES</strong>
        </p>
        <p>
          CURECALL agit en qualité de Responsable de traitement pour les données
          à caractère personnel du Patient relatives aux comptes créés par les
          Professionnels de santé pour le suivi de leur Patient.
        </p>
        <p>Seules ces données seront conservées par CURECALL.</p>
        <p>
          Le Patient est informé(e) que seules ses données à caractère personnel
          sont conservées pendant la durée nécessaire pour l’utilisation de la
          Solution.
        </p>
        <p>
          A la fin de l’utilisation de la Solution, CURECALL conservera les
          données en archivage intermédiaire pendant une durée de 3 ans.
        </p>
        <p>
          Passé un ce délai de 3 ans, lesdites données seront ensuite supprimées
          sous la responsabilité de CURECALL.
        </p>
        <p>
          Le Centre Médical Ophtalmologique agit également en qualité de
          Responsable de traitement pour les données à caractère personnelles du
          Patient relative à son dossier médical et au suivi mis en place avec
          la Solution.
        </p>
        <p>
          Le Patient est informé que les données relatives relative à son
          dossier médical et au suivi mis en place avec la Solution seront
          restituées au Centre médical ophtalmologique à sa demande.
        </p>
        <p>
          La conservation de ces données est à la charge du Centre Médical
          Ophtalmologique.
        </p>
        <p>
          <strong>7. LES DROITS DU PATIENT SUR SES DONNEES PERSONNELLES</strong>
        </p>
        <p>
          Le Patient dans les conditions fixées par loi « Informatique et
          libertés » du 6 janvier 1978 ainsi qu’au Règlement 2016/679 du
          Parlement Européen et du Conseil du 27 avril 2016: d’un droit d’accès,
          d’un droit de rectification, d’un droit d’opposition, du droit de
          définir des directives concernant le sort de ses données après son
          décès, du droit de solliciter une limitation du traitement, d’un droit
          à l’oubli et à l’effacement numérique (sauf pour ses données
          collectées à des fins de pharmacovigilance, conformément aux
          obligations légales), d’un droit à la portabilité de ses données.
        </p>
        <p>
          Pour exercer ses droits, le Patient peut s’adresser aux coordonnées
          précisées ci-après au point 6 “Exercer ses droits”.
        </p>
        <p>
          Enfin, le Patient dispose du droit d’introduire une réclamation auprès
          de la CNIL (
          <a href="http://www.cnil.fr/fr/cnil-direct/question/844">
            www.cnil.fr/fr/cnil-direct/question/844
          </a>
          ; 3, place de Fontenoy, TSA 80715 – 75334 Paris Cedex 07, Tél. : 01 53
          73 22 22).
        </p>
        <p>
          Le Patient est par ailleurs invité(e) à prendre connaissance de la
          Politique de confidentialité accessible librement et à tout moment sur
          notre site internet.
        </p>
        <p>
          <strong>8. EXERCER SES DROITS EN TANT QUE PATIENT</strong>
        </p>
        <p>
          Si le Patient souhaite retirer son consentement, exercer ses droits
          d’accès, de rectification, d’opposition, de limitation, d’effacement,
          de portabilité de ses données à caractère personnel ainsi que définir
          des directives concernant le sort de ses données après son décès.
        </p>
        <p>Le Patient peut le faire en écrivant à CURECALL :</p>
        <ul>
          <li>
            Par courrier à son adresse postale : 15 rue de Montmorency 75003
            Paris
          </li>
          <li>
            Par mail à l’adresse suivante <strong>\:</strong>{" "}
            <a href="mailto:donnes.pesonnelles@curecall.com">
              donnes.pesonnelles@curecall.com
            </a>
          </li>
        </ul>
        <p>
          Le Patient peut également en informer par tout moyen le Centre Médical
          Ophtalmologique via le Professionnel de Santé lequel transmettra sa
          demande à CURECALL.
        </p>
        <p>
          Pour toute question relative à ses données à caractère personnel, le
          Patient peut écrire un mail à la déléguée à la protection des données
          désignée par CURECALL :
        </p>
        <p>Madame Laetitia ORMUS</p>
        <p>
          <a href="mailto:donnes.pesonnelles@curecall.com">
            donnes.pesonnelles@curecall.com
          </a>
        </p>
      </div>
    </div>
  )
}

export default CGU
